import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import SocialShare from '../components/social-share';
import StickySidebar from '../../node_modules/sticky-sidebar-v2';
import Comments from '../components/Comments';
import PromotionPopup from '../components/PromotionPopup';

export default class CourseTemplate extends Component {
	componentDidMount() {
		// TODO: get the headings form the query
		const headings = document.querySelectorAll('.post-single h2');
		let tocHtml = '';
		let mobileTocHtml = '';
		if (headings && headings.length) {
			if (typeof window !== 'undefined' && window.screen.width > 860) {
				// DESKTOP TOC
				headings.forEach((heading, i) => {
					// for desktop
					tocHtml += `<li id='lesson_${i + 1}'>${heading.textContent}</li>`;
					const originalHeadingContent = heading.innerHTML;
					const anchor = `<a id="lesson_${
						i + 1
					}" class="offset-anchor" name="lesson_${i}"></a>`;
					heading.innerHTML = anchor + originalHeadingContent;
				});
			} else if (typeof window !== 'undefined' && window.screen.width <= 860) {
				// MOBILE TOC
				headings.forEach((heading, i) => {
					// for mobile
					mobileTocHtml += `<a class="dropdown-item" id='lesson_${i + 1}'>${
						i + 1
					}. ${heading.textContent}</a>`;
					const originalHeadingContent = heading.innerHTML;
					const anchor = `<a id="lesson_${
						i + 1
					}" class="offset-anchor" name="lesson_${i}"></a>`;
					heading.innerHTML = anchor + originalHeadingContent;
				});
			}

			const htmlFragment = `<ul>${tocHtml}</ul>`;
			// add the TOC at the beginning of the content
			const courseNavigation = document.getElementById(
				'sticky-navigation-content'
			);
			let navigationItems;
			if (courseNavigation) {
				// not all pages that use this template have the sidenav
				const originalContent = document.getElementById(
					'sticky-navigation-content'
				).innerHTML;

				if (typeof window !== 'undefined' && window.screen.width > 860) {
					// desktop
					document.querySelector('#sticky-navigation-content').innerHTML =
						htmlFragment + originalContent;
					new StickySidebar('#sticky-navigation', {
						containerSelector: '#sticky-navigation-container',
						innerWrapperSelector: '#sticky-navigation__inner',
						topSpacing: 100,
						bottomSpacing: 20,
					});
					navigationItems = document.querySelectorAll(
						'#sticky-navigation-content li'
					);
				} else if (
					typeof window !== 'undefined' &&
					window.screen.width <= 860
				) {
					// mobile
					document.querySelector('#dropdown-menu').innerHTML = mobileTocHtml;
					navigationItems = document.querySelectorAll('#dropdown-menu a');
				}

				for (const item of navigationItems) {
					item.addEventListener('click', (e) => {
						e.preventDefault();
						e.stopPropagation();
						// toggle only the current section to be visible
						renderCurrentSectionVisible(item);
						const dropDownMenu = document.querySelector('.dropdown-menu');
						if (dropDownMenu) {
							dropDownMenu.classList.remove('show');
						}
					});
				}
				const body = document.querySelector('body');
				body.addEventListener('click', () => {
					// close toc menu on click of the body
					const dropDownMenu = document.querySelector('.dropdown-menu');
					if (dropDownMenu) {
						dropDownMenu.classList.remove('show');
					}
				});

				const getCurrentSectionContent = (navigationItemID) => {
					const currentHeader = document.querySelector(
						`h2 a#${navigationItemID}`
					).parentNode;
					const elements = [currentHeader];
					// ensure navigation buttons are never hidden
					const navigationButtons =
						document.querySelector(`#navigation-buttons`);
					let elem = currentHeader;
					elem = elem.nextElementSibling;
					while (elem) {
						// If we've reached our match, bail
						if (elem.matches('h2')) break;
						// Otherwise, push it to the siblings array
						elements.push(elem);
						// Get the next sibling element
						elem = elem.nextElementSibling;
					}
					elements.push(navigationButtons);

					// ensure donate always shows
					const buyMeCoffee = document.querySelector('.coffee-row');
					elements.push(buyMeCoffee);
					// ensure comments are always visible
					const comments = document.querySelector('.comment-row');
					elements.push(comments);
					return elements;
				};

				const renderCurrentSectionVisible = (navigationItem) => {
					if (!navigationItem) {
						return;
					}
					const navigationItemsArray = Array.from(navigationItems);
					const index = navigationItemsArray.indexOf(navigationItem);
					const currentSectionContent = getCurrentSectionContent(
						navigationItem.id
					);
					const page = document.querySelector('.post-single');
					if (page.childNodes?.length) {
						for (const node of page.childNodes) {
							if (node.localName === 'iframe') {
								// iframe are different because they are loaded again when i switch page causing the node to be different, look at their src
								// note: CHECK EL exist or it will error badly
								if (
									currentSectionContent.some((el) => el && node.src === el.src)
								) {
									node.classList?.remove('hidden');
								} else {
									node.classList?.add('hidden');
								}
							} else {
								if (currentSectionContent.some((el) => node.isSameNode(el))) {
									// make them visibile again
									node.classList?.remove('hidden');
								} else {
									// only hide what's not in this current section
									node.classList?.add('hidden');
								}
							}
						}
					}

					// highlight the current LI item
					for (const item of navigationItems) {
						item.classList.remove('item-active');
					}
					navigationItem.classList?.add('item-active');
					// append next and previous lesson at the bottom of the DOM
					appendNextPreviousButton(navigationItem);
					appendUrlParams(index);
					// scroll to top
					setTimeout(() => {
						window.scrollTo(0, 0);
					});
				};

				const appendUrlParams = (index) => {
					window.location.hash = '';
					window.location.hash = `lesson_${index + 1}`;
				};
				const appendNextPreviousButton = (navigationItem) => {
					clearNavigationButtons();
					const navigationItemsArray = Array.from(navigationItems);
					const index = navigationItemsArray.indexOf(navigationItem);
					const previous = navigationItemsArray[index - 1];
					const next = navigationItemsArray[index + 1];

					let buttons = '';
					if (previous) {
						const previousButton = `<button class="btn-simple btn-secondary-outline mt-4" id="previous-lesson-button">Prev Lesson</button>`;
						buttons += previousButton;
					}

					if (next) {
						const nextButton = `<button class="btn-simple btn-secondary-solid mt-4" id="next-lesson-button">Next Lesson</button>`;
						buttons += nextButton;
					}

					// add both at the same time to ensure both get their event listeners
					document.querySelector('#navigation-buttons').innerHTML += buttons;

					if (previous) {
						document
							.getElementById('previous-lesson-button')
							.addEventListener('click', () => {
								renderCurrentSectionVisible(previous);
							});
					}
					if (next) {
						document
							.getElementById('next-lesson-button')
							.addEventListener('click', () => {
								renderCurrentSectionVisible(next);
							});
					}
				};

				const clearNavigationButtons = () => {
					document.querySelector('#navigation-buttons').innerHTML = '';
				};
				// set the first one to display
				if (window && window.location.hash) {
					const index = window.location.hash.split('#lesson_')[1];
					// index is always one ahead
					renderCurrentSectionVisible(navigationItems[index - 1]);
				} else {
					renderCurrentSectionVisible(navigationItems[0]);
				}

				const mobileTocToggle = document.querySelector('.dropdown-toggle');
				if (mobileTocToggle) {
					mobileTocToggle.addEventListener('click', (e) => {
						e.preventDefault();
						e.stopPropagation();
						const mobileToc = document.getElementById('dropdown-menu');
						mobileToc.style.position = 'absolute';
						mobileToc.style.top = 0;
						mobileToc.style.left = 0;
						// 36px for each item in the list
						const height = headings.length * 35;
						mobileToc.style.transform = `translate3d(-21px, -${height}px, 0px)`;
						//                     x-placement="top-start"
						// style="position: absolute; transform: translate3d(-21px, -524px, 0px); top: 0px; left: 0px; will-change: transform;"
						mobileToc.classList.toggle('show');
					});
				}
			}
		}
	}

	constructor(props) {
		super(props);
		this.showSidenav = props.pageContext.showSidenav;
		// if sidenav present, smaller content
		this.mainContentClass = this.showSidenav
			? 'col-12 col-lg-9'
			: 'col-12 col-lg-9';

		this.frontmatter = props.data.mdx.frontmatter;
		this.disqusProps = {
			shortname: 'HowToVietnamese',
			config: {
				identifier: props.pageContext.id, // identify of the post
				title: this.frontmatter.title, // title of the post
			},
		};
		this.category = this.frontmatter.category;
		// this.categoryImage = this.frontmatter.categoryImage
		this.title = this.frontmatter.title;
		this.path = this.frontmatter.path;
		this.excerpt = this.frontmatter.excerpt;
		this.keywords = this.frontmatter.keywords;
		this.image = this.frontmatter.image;
		this.level = this.frontmatter.level;
		this.body = props.data.mdx.body;
		this.id = props.pageContext.id;
		if (this.category === 'Courses') {
			this.pageTitle = this.title;
		} else {
			this.pageTitle = `${this.title} in Vietnamese for ${
				this.level.charAt(0).toUpperCase() + this.level.slice(1)
			}`;
		}
	}

	render() {
		this.url = `https://howtovietnamese.com${this.path}`;
		return (
			<Layout bodyclassName="page-service">
				<SEO
					title={this.pageTitle}
					keywords={this.keywords}
					image={this.image.childImageSharp.fixed.src}
					description={this.excerpt}
					url={this.url}
				/>
				<PromotionPopup />

				<div className="strip-white mt-6">
					<div className="row no-gutters reverse-flex-mobile bg-white box-shadow">
						{/* SIDE NAVIGATION */}
						{this.showSidenav ? (
							<React.Fragment>
								{/* desktop toc */}
								<div
									id="sticky-navigation-container"
									className="col-12 col-lg-3 hide-on-mobile border-right"
								>
									<div
										className="sidebar sticky-navigation__inner"
										id="sticky-navigation"
									>
										<h3 id="sticky-navigation-header">Table of Content</h3>
										<div id="sticky-navigation-content"></div>
									</div>
								</div>
								{/* mobile toc */}
								<div className="container hide-on-desktop box-shadow mobile-related-container">
									<div className="dropup">
										<button
											type="button"
											className="btn text-white dropdown-toggle"
											data-display="static"
											aria-haspopup="true"
											aria-expanded="false"
										>
											Table of content
										</button>
										<div className="dropdown-menu" id="dropdown-menu"></div>
									</div>
								</div>
							</React.Fragment>
						) : null}

						{/* CONTENT */}
						<div className={this.mainContentClass}>
							<div className="container pb-6 pl-2 pr-2">
								<div className="row d-flex justify-content-center">
									<div className="col-12 col-md-9">
										<div className="service post-single pt-md-3 mb-2">
											<MDXRenderer>{this.body}</MDXRenderer>
											<div id="navigation-buttons"></div>
											<SocialShare
												title={this.title}
												excerpt={this.excerpt}
												path={this.path}
											/>
										</div>
									</div>

									{/* Buy me a Coffee */}
									{/* <div className="border rounded bmac-container box-shadow mt-8 pt-4 pb-4">
                    <div className="row justify-content-center">
                      <p className="font-weight-bold text-align-center ml-3 mr-3 ml-md-0 mr-md-0 mb-2">
                        Loving the content? Support me with a Coffee ❤️
                      </p>
                      <div className="col-9 col-md-5">
                        <BuyMeCoffee></BuyMeCoffee>
                      </div>
                    </div>
                  </div> */}

									{/* comments */}
									<div className="col-12 col-md-9 mt-8 pl-0 pr-0 comment-row">
										<Comments href={this.url}></Comments>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}

export const query = graphql`
	query ($id: String!) {
		mdx(id: { eq: $id }) {
			body
			frontmatter {
				title
				category
				path
				excerpt
				keywords
				level
				image {
					childImageSharp {
						fluid(maxWidth: 300) {
							...GatsbyImageSharpFluid
						}
					}
					childImageSharp {
						fixed(width: 1200) {
							...GatsbyImageSharpFixed
						}
					}
				}
			}
		}
	}
`;
